function helpGtagView(page_title, page_path) {
    //Exemplo: send, header, logo, click
    // ga($modo, $pagina, $elemento, $tipo);
    gtag('config', 'UA-98286807-1', {
        page_title: page_title,
        page_path : page_path
    });
}

function helpGtagEvent(event, event_category, event_label) {
    gtag('event', event, {
        event_category: event_category,
        event_label   : event_label
    });
}

// $('.cliquePageView').on('click', function(){
//     var pagina = $(this).data('pagina');
//     var elemento = $(this).data('elemento');
//     var tipo = $(this).data('tipo');
//     helpGtagEvent(tipo, pagina, elemento);
// });

$('.cliqueEvento').on('click', function(){
    var pagina = $(this).data('pagina');
    var elemento = $(this).data('elemento');
    var tipo = $(this).data('tipo');
    helpGtagEvent(tipo, pagina, elemento);
});