
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 1) {
            $("header").addClass('scroll');
        } else if ($(window).scrollTop() < 1) {
            $("header").removeClass('scroll');
        }
    });

$('#rollLinks').localScroll({
    target:'body',
    // offset: -97
});

$('.botaoResponsivo').on('click', function(){
    $('.menu nav').toggleClass('ativo');
    $('.linhasmr').toggleClass('ativo');
    if ($('.linhasmr').hasClass('ativo')) {
        $(".linhasmr").addClass("ativo");
    } else {
        $(".linhasmr").removeClass("ativo");
    }
    return false;
});

$('.menu nav a').on('click', function(){
    $('.menu nav').removeClass('ativo');
    $('.linhasmr').removeClass('ativo');
});

$('.menu nav ul li').on('mouseenter', function(){
    let dataSubMenu = $(this).data('submenu');
    $("header").addClass('black');
    $('.subMenus .subMenu').removeClass('ativo');
    $('.subMenus').removeClass('active');
    if(dataSubMenu != 'n') {
        $('.menu nav ul li').removeClass('ativo');
        $(this).addClass('ativo');
        $('.subMenus').addClass('active');
        // $('.subMenus .subMenu').fadeOut('fast');
        // $('.subMenus .subMenu').addClass('ativo');
        $('.subMenus #' + dataSubMenu).addClass('ativo');
    }
});

$('.subMenu').on('mouseleave', function(){
    console.log(isHome);
    if((isHome === true) && ($(window).scrollTop() < 1)) {
        $("header").removeClass('black');
    }
    $('.subMenus').removeClass('active');
    $('.menu nav ul li').removeClass('ativo');
    // $('.subMenus .subMenu').fadeOut('fast');
    $('.subMenus .subMenu').removeClass('ativo');
});