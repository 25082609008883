var controller = new ScrollMagic.Controller();

// /************************* HOME */
//Vitrine
setTimeout(function(){
    $('.vitrine').addClass('active');
}, 500);

var topDiff = 0;
// var tween = {};

var passiveSupported = false;
window.addEventListener("mousewheel", function () {}, passiveSupported ? { passive: true } : false);

//Home > Saudável
new ScrollMagic.Scene({
    triggerElement: '.saudavel',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".saudavel", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'saudavel' }) // add indicators (requires plugin)
    .addTo(controller);

//Home > Receitas
new ScrollMagic.Scene({
    triggerElement: '.receitas',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".receitas", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'receitas' }) // add indicators (requires plugin)
    .addTo(controller);

//Home > Produtos
new ScrollMagic.Scene({
    triggerElement: '.produtos',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".produtos", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'produtos' }) // add indicators (requires plugin)
    .addTo(controller);

//Home > Conservantes
new ScrollMagic.Scene({
    triggerElement: '.conservantes',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".conservantes", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'conservantes' }) // add indicators (requires plugin)
    .addTo(controller);

//Home > Respeito
new ScrollMagic.Scene({
    triggerElement: '.respeito',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -540,
    loglevel      : 2
})
    .setClassToggle(".respeito", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'respeito' }) // add indicators (requires plugin)
    .addTo(controller);

    //Home > Instagram
new ScrollMagic.Scene({
    triggerElement: '.instagram',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -540,
    loglevel      : 2
})
    .setClassToggle(".instagram", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'instagram' }) // add indicators (requires plugin)
    .addTo(controller);

//Home > Chamada HERO
new ScrollMagic.Scene({
    triggerElement: '.hero',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -40,
    loglevel      : 2
})
    .setClassToggle(".hero", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'respeito' }) // add indicators (requires plugin)
    .addTo(controller);

// Nat Verde BL1
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl1',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -40,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl1", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'respeito' }) // add indicators (requires plugin)
    .addTo(controller);

// Nat Verde BL2
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl2',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl2", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

    // Nat Verde BL2
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl3 .alimentacao',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl3 .alimentacao", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

    // Nat Verde BL2
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl3 .antibioticos',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl3 .antibioticos", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

// Nat Verde BL2
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl3 .granjas',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl3 .granjas", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

// Nat Verde BL2
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl3 .bemestar',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl3 .bemestar", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

// Nat Verde BL2
new ScrollMagic.Scene({
    triggerElement: '.natverde .bl3 .linhaProdutos',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".natverde .bl3 .linhaProdutos", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);


// JEITO NAT BL1
new ScrollMagic.Scene({
    triggerElement: '.jeitoNat .bl1',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -40,
    loglevel      : 2
})
    .setClassToggle(".jeitoNat .bl1", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);
    
// JEITO NAT BL2
new ScrollMagic.Scene({
    triggerElement: '.jeitoNat .bl2',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".jeitoNat .bl2", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

// JEITO NAT HISTORIA
new ScrollMagic.Scene({
    triggerElement: '.jeitoNat .historia',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".jeitoNat .historia", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);

// JEITO NAT PARCERIA
new ScrollMagic.Scene({
    triggerElement: '.jeitoNat .parceria',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".jeitoNat .parceria", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Nat Verde BL2' }) // add indicators (requires plugin)
    .addTo(controller);




// FOOTER
new ScrollMagic.Scene({
    triggerElement: 'footer',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -750,
    loglevel      : 2
})
    .setClassToggle("header", "foceHide") // add class toggle
    // .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'Footer' }) // add indicators (requires plugin)
    .addTo(controller);

// 404
new ScrollMagic.Scene({
    triggerElement: '.notFound',
    triggerHook   : 0,
    duration      : topDiff,
    offset        : -440,
    loglevel      : 2
})
    .setClassToggle(".notFound", "activeAnimations") // add class toggle
    .reverse(false) //Nao remove a classe ao continuar
    // .setPin('activeAnimation')
    // .addIndicators({ name: 'saudavel' }) // add indicators (requires plugin)
    .addTo(controller);