//Loader
$(window).on('load', function(){
    "use strict";
    $("#loader")
    .delay(350)
    .fadeOut(function() {
        jQuery("body")
            .delay(350)
            .css({ overflow: "visible" });
    });
});

function openLoader() {
    $('#loader').fadeIn();
}
function closeLoader() {
    $('#loader').fadeOut();
}

