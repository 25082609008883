let receitasFavoritasStorage = JSON.parse(localStorage.getItem("natEspecialidadesFavoritos") || '[]') || [];
var optionsCarouselEspecialidades = {
    loop: false,
    nav: true,
    dots: false,
    margin: 15,
    stagePadding: 50,
    responsiveClass: true,
    responsive:{
        0:{
            items:1,
            nav: false,
        },
        600:{
            items:3,
            nav: false,
        },
        1000:{
            items:4,
            nav: true,
        },
        1440:{
            items:4,
            nav: true,
        },
        1600:{
            items:4,
            nav: true,
        },
        1700:{
            items:5,
            nav: true,
        }
    }
}
if(receitasFavoritasStorage.length > 0) {
    
    var carouselFavoritos = $('.carouselFavoritos');
    carouselFavoritos.html('');

    $.get(
        url + "/ajax/especialidades_favoritas.php", { receitas: receitasFavoritasStorage },
        function(data) {
            carouselFavoritos.html(data.receitas.map(receita => templateCarouselReceitas('item', receita.link, receita.titulo, receita.imagem_destaque[0], receita.duracao, receita.id)));
            carouselFavoritos.owlCarousel(optionsCarouselEspecialidades);
            $('#receitasFavoritas').fadeIn();
        }
    );
}

var carouselSaudaveis = $('.carouselSaudaveis');
if(carouselSaudaveis.length > 0) {
    carouselSaudaveis.owlCarousel(optionsCarouselEspecialidades);
}

var carouselPraticas = $('.carouselPraticas');
if(carouselPraticas.length > 0) {
    carouselPraticas.owlCarousel(optionsCarouselEspecialidades);
}

var carouselFrangos = $('.carouselFrangos');
if(carouselFrangos.length > 0) {
    carouselFrangos.owlCarousel(optionsCarouselEspecialidades);
}

var pesquisaEspecialidades = $('#pesquisa');
var tagPesquisaEspecialidades = $('.tagReceita');

$('.closeSearch').on('click', function(){
    $('body').removeClass('activeBusca');
    $('.heroEspecialidade').removeClass('comBusca');
    $('.tag').removeClass('active');
    $('.tag').fadeIn('fast');
    pesquisaEspecialidades.val('');
    $('.resultadosBusca').html('');
});

const debounce = (func, wait) => {
    let timeout;
  
    // return function executedFunction(...args) {
    return function executedFunction(ev) {
        const later = () => {
            clearTimeout(timeout);
            func(ev);
        };
  
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

function searchReceitas($palavra, $type = 'field'){
    $('.resultadosBusca').html('');
    if($palavra) {
        $.get(
            url + "/ajax/especialidades_search.php?s="+$palavra,
            function(data) {
                // console.log(data);
                if(data.total === 0) {
                    $('.resultadosBusca').html(templateSemReceitas());
                    $('.heroEspecialidade').addClass('comBusca');
                } else {
                    if($type === 'field') {
                        $('.tagReceita').removeClass('active');
                        $('.tagReceita').fadeOut();
                    }
                    $('.resultadosBusca').html(data.receitas.map((receita, index) => templateCarouselReceitas('resultado', receita.link, receita.titulo, receita.imagem_destaque[0], receita.duracao, receita.id, index)));
                    $('.heroEspecialidade').addClass('comBusca');
                }
            }
        );
    } else {
        $('.heroEspecialidade').removeClass('comBusca');
        $('.tagReceita').fadeIn();
    }
}

function templateCarouselReceitas(classe, link, titulo, imagem, duracao, id, index, $palavra) {
    let template;
    var palavraBusca = $('#pesquisa').val();
    if(index === 0) {
        template = `
            <h2 class="text-center">Listando todas as receitas para "${palavraBusca}"</h2>
            <div class="${classe}">
                <div class="favorito" id="fav${id}">
                    <div class="icone noFav" data-idreceita="${id}"><i class="far fa-heart"></i></div>
                    <div class="icone fav active" data-idreceita="${id}"><i class="fas fa-heart"></i></div>
                </div>
                <a href="${link}" class="cliqueEvento" data-pagina="receitas" data-elemento="${titulo}" data-tipo="click">
                    <figure>
                        <img src="${imagem}" alt="${titulo}" class="img-fluid mx-auto d-block">
                    </figure>
                    <div class="dados">
                        <div class="row">
                            <div class="col-6">
                                ${duracao}
                            </div>
                        </div>
                        <h3>${titulo}</h3>
                    </div>
                </a>
            </div>
        `;
    } else {
        template = `
            <div class="${classe}">
                <div class="favorito" id="fav${id}">
                    <div class="icone noFav" data-idreceita="${id}"><i class="far fa-heart"></i></div>
                    <div class="icone fav active" data-idreceita="${id}"><i class="fas fa-heart"></i></div>
                </div>
                <a href="${link}" class="cliqueEvento" data-pagina="receitas" data-elemento="${titulo}" data-tipo="click">
                    <figure>
                        <img src="${imagem}" alt="${titulo}" class="img-fluid mx-auto d-block">
                    </figure>
                    <div class="dados">
                        <div class="row">
                            <div class="col-6">
                                ${duracao}
                            </div>
                        </div>
                        <h3>${titulo}</h3>
                    </div>
                </a>
            </div>
        `;
    }
    return template
}

function templateSemReceitas() {
    var template = `<h2 class="text-center">Nenhuma receita encontrada.</h2>`;
    return template;
}

if(pesquisaEspecialidades.length > 0) {
    $("#formEspecialidades").attr('autocomplete', 'off');
    pesquisaEspecialidades.on('click', function(){
        $('body').addClass('activeBusca');
        $('html, body').animate({
			scrollTop: $('.especialidades').offset().top
		}, 300);
    });

    tagPesquisaEspecialidades.on('click', function(){
        var pesquisaAtual = pesquisaEspecialidades.val();
        var tagSearch = $(this).data('tag');

        if(pesquisaAtual === tagSearch) {
            $(this).removeClass('active');
            $('.tag').fadeIn('fast');
            pesquisaEspecialidades.val('');
            $('.resultadosBusca').html(templateSemReceitas());
            $('.heroEspecialidade').removeClass('comBusca');
            
        } else {
            pesquisaEspecialidades.val('');
            $('.tag').removeClass('active');
            $(this).addClass('active');
            $('.tag:not(.active)').fadeOut('fast');
            pesquisaEspecialidades.val(tagSearch);
            searchReceitas(tagSearch, 'tagReceita');
        }

        $('html, body').animate({
			scrollTop: $('.especialidades').offset().top
		}, 300);
    });

    pesquisaEspecialidades.on('keyup change', debounce((ev) => searchReceitas(ev.currentTarget.value), 300));

}