//=require _commom/jquery-3.4.1.min.js
//=require _commom/html5shiv.min.js
//=require _commom/respond.min.js

// function getMobileOperatingSystem() {
//   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

//       // Windows Phone must come first because its UA also contains "Android"
//     if (/windows phone/i.test(userAgent)) {
//         return "Windows Phone";
//     }

//     if (/android/i.test(userAgent)) {
//         return "Android";
//     }

//     // iOS detection from: http://stackoverflow.com/a/9039885/177710
//     if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//         return "iOS";
//     }

//     return "unknown";
// }
// if(getMobileOperatingSystem() === 'iOS') {
//   document.addEventListener('touchmove', function (event) {
//     if (event.scale !== 1) { event.preventDefault(); }
//   }, { passive: false });
// }

// document.addEventListener('touchmove', function (event) {
//   if (event.scale !== 1) { event.preventDefault(); }
// }, { passive: false });

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ){
      if ( ns.includes("noPreventDefault") ) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };

//=require ../../../../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../../../../node_modules/owl.carousel/dist/owl.carousel.js
//=require ../../../../../node_modules/scrollreveal/dist/scrollreveal.min.js
//=require ../../../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min.js
//=require ../../../../../node_modules/jquery.localscroll/jquery.localScroll.min.js
//=require ../../../../../node_modules/jquery.scrollto/jquery.scrollTo.min.js
//=require ../../../../../node_modules/selectric/src/jquery.selectric.js
//=require ../../../../../node_modules/@fortawesome/fontawesome-free/js/all.js
//=require ../../../../../node_modules/scrollmagic/scrollmagic/minified/ScrollMagic.min.js
//=require ../../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js


//=require componentes/loader.js
//=require componentes/tracking.js
//=require componentes/animacoes.js
//=require componentes/menu.js
//=require componentes/instagram.js
//=require componentes/cep.js
//=require componentes/mascaras.js
//=require componentes/vitrine.js
//=require componentes/formularios.js
//=require componentes/receitas.js
//=require componentes/produtos.js
//=require componentes/conservantes.js
//=require componentes/jeitonat.js
//=require componentes/favoritos.js
//=require componentes/gostou.js
//=require componentes/especialidades.js