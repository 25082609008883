// $('#tipoCorte, #duracao, #dificuldade').on('change', function(){
//     var pagina = 1;
//     $('#paginacao').val('2');
//     var tipoCorte = $('#tipoCorte').val();
//     var duracao = $('#duracao').val();
//     var dificuldade = $('#dificuldade').val();

//     helpGtagEvent('click', 'filtro-receitas', dificuldade + '/' + duracao + '/' + tipoCorte);

//     $('.listagemReceitas').fadeOut('slow');

//     $.get(
//         url + "/ajax/especialidades.php",
//         {
//             tipoCorte: tipoCorte,
//             duracao: duracao,
//             dificuldade: dificuldade
//         },
//         function(data) {
//             if(data.total > 0) {
//                 $('.semResultados').fadeOut();
//                 $('.listagemReceitas').html('');
//                 for (var objKey in data.receitas) {
//                     // console.log(data.receitas[objKey]);
//                     $('.listagemReceitas').append(
//                         templateReceitas(data.receitas[objKey].link, data.receitas[objKey].titulo, data.receitas[objKey].imagem_destaque[0], pagina)
//                     );
//                 }
//                 $('.listagemReceitas').fadeIn('slow', function(){
//                 });
//                 setTimeout(() => {
//                     $('.receitaNoAnime').addClass('reanime');
//                 }, 500);
                
//                 if($('.receita').length >= data.total) {
//                     $('#loadMaisReceitas').fadeOut();
//                 } else {
//                     $('#loadMaisReceitas').fadeIn();
//                 }
//             } else {
//                 $('.listagemReceitas').html('');
//                 $('#loadMaisReceitas').fadeOut();
//                 $('.semResultados').fadeIn();
//             }
//         }
//     );
// });

// $('#loadMaisReceitas').on('click', function(){
//     var pagina = parseInt($('#paginacao').val());
//     var tipoCorte = $('#tipoCorte').val();
//     var duracao = $('#duracao').val();
//     var dificuldade = $('#dificuldade').val();
//     $.get(
//         url + "/ajax/especialidades.php",
//         {
//             p: pagina,
//             tipoCorte: tipoCorte,
//             duracao: duracao,
//             dificuldade: dificuldade
//         },
//         function(data) {
//             $('#paginacao').val(pagina + 1);
//             for (var objKey in data.receitas) {
//                 // console.log(data.receitas[objKey]);
//                 $('.listagemReceitas').append(
//                     templateReceitas(data.receitas[objKey].link, data.receitas[objKey].titulo, data.receitas[objKey].imagem_destaque[0], pagina)
//                 );
//             }
//             setTimeout(() => {
//                 $('.receitaNoAnime').addClass('reanime');
//             }, 500);
            
//             if($('.receita').length >= data.total) {
//                 $('#loadMaisReceitas').fadeOut();
//             }
//         }
//     );
// });

// function templateReceitas(permalink, titulo, imagem, pagina) {
//     var templateReceitas = `
//         <div class="col-md-4 col-12">
//             <div class="receita receitaNoAnime">
//                 <a href="${permalink}">
//                     <figure>
//                         <img src="${imagem}" alt="${titulo}" class="img-fluid mx-auto d-block">
//                     </figure>
//                     <h2>
//                         ${titulo}
//                     </h2>
//                     <div class="seta"></div>
//                 </a>
//             </div>
//         </div>
//     `;
//     return templateReceitas;
// }

if($('.setaScrollReceita')) {
    //Seta Rolagem
    $(window).on("scroll", function() {
        var scrollHeight = $(document).height();
        var scrollPosition = $(window).height() + $(window).scrollTop();
        if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
            $('.setaScrollReceita').fadeOut();
        } else {
            $('.setaScrollReceita').fadeIn();
        }
    });
}

$('.closeModal').on('click', function(){
    $('.modalVideo').fadeOut();
});
$('.video a').on('click', function(){
    $('.modalVideo').fadeIn();
});

//Carousel de Receitas da Home
var receitasHome = $('.carouselReceitasHome');
if(receitasHome.length > 0) {
    receitasHome.owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        items: 3,
        stagePadding: 0,
        responsive:{
            0:{
                items:1,
                nav:true,
                stagePadding: 50
            },
            680:{
                items:1,
                nav:false,
                stagePadding: 50
            },
            768:{
                items:3,
                nav:false,
                loop:false,
                stagePadding: 0
            },
            1000:{
                items:3,
                nav:true,
                loop:false,
                stagePadding: 0
            }
        }
    });
}

//Carousel de Imagem de Single Receitas
var imagensReceita = $('.carouselReceita');
if(imagensReceita.length > 0) {
    imagensReceita.owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        items: 1
    });
}

$('#printPage').on('click', function(){
    window.print();
});

if($('.receitas.single .voiceLink').length > 0){
    $('.receitas.single .voiceLink').on('click', function(e){
        e.preventDefault();
        var urlDestino = $(this).data('link');
        var nomeReceita = $(this).data('receita');

        var urlClick = url+'/ajax/especialidades_click.php';

        var data = { name: nomeReceita };

        $.ajax({
            type: 'POST',
            url: urlClick,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            // contentType: 'application/json; charset=utf-8',
            dataType: "JSON",
            // data: data,
            data: JSON.stringify( data ),
            success: function(data) {
                window.location.href = urlDestino;
            },
            error: function(data) {
                // var mensagemErro = $.parseJSON(data.responseText);
                console.log('Status: '+data.status);
                // $("#btnEnviarRevista").html('Faça sua Inscrição');
                // closeLoader();
            }
        });
    });
}