var carouselFrases = $(".carouselFrases");
if(carouselFrases.length > 0) {
    carouselFrases.owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        items: 1,
        autoplay: true,
        animateOut: 'fadeOut',
        autoplayTimeout:7000,
        smartSpeed:1000
    });

    carouselFrases.on('translate.owl.carousel', function(e){
        idx = e.item.index;
        // $('.carouselFrases .owl-item').eq(idx).css({opacity: 0});
    });

}
