function getInstagram() {
    $.getJSON('https://www.instagram.com/somosnat/?__a=1', function(dados) {
        var lista = dados.graphql.user.edge_owner_to_timeline_media.edges;

        // console.log(lista);

        for (var i = 0; i < lista.length; i++) {
            var listaElement = lista[i].node;
            var url = 'https://www.instagram.com/p/' + listaElement.shortcode;
            var image = listaElement.thumbnail_src;

            $('.carouselInstagram').append(
                templateInstagram(url, image)
            );

            // console.log(listaElement);
            // console.log(image);
        }

        $(".carouselInstagram").owlCarousel({
            loop:true,
            touchDrag: true,
            dots: false,
            margin:10,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true,
                    stagePadding: 30,
                    loop:false
                },
                600:{
                    items:3,
                    nav:false
                },
                1000:{
                    items:3,
                    nav:true,
                    loop:false
                }
            }
        });

    });

    // console.log(html);
}

// getInstagram();

function templateInstagram(url, image) {
    var template = `
        <div class="item">
            <a href="${url}" target="_blank">
                <img src="${image}" alt="Instagram" class="img-fluid mx-auto d-block">
            </a>
        </div>
    `;
    return $(template);
};

var vitrineSite = $('.vitrine');
if(vitrineSite.length > 0) {
    vitrineSite.find(".carousel").owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        autoplay: true,
        autoplayTimeout:5000,
        smartSpeed:1000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: false,
        touchDrag: true
    });

    $('.btn-down').on('click', function(){
        $('html, body').animate({
            scrollTop: $('#quem-somos').offset().top - 60
        }, 1000);
    });
}