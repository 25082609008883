var vitrineSite = $('.vitrine');

var fixOwl = function(){
    var $stage = $('.owl-stage'),
        stageW = $stage.width(),
        $el = $('.owl-item'),
        elW = 0;
    $el.each(function() {
        elW += $(this).width()+ +($(this).css("margin-right").slice(0, -2))
    });
    if ( elW > stageW ) {
        $stage.width( elW );
    };
}

if(vitrineSite.length > 0) {
    var $owl = vitrineSite.find(".carousel").owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        autoplay: true,
        autoplayTimeout:5000,
        smartSpeed:1000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: false,
        touchDrag: true,
        onInitialized: fixOwl,
        onRefreshed: fixOwl
    });

    $('.btn-down').on('click', function(){
        $('html, body').animate({
            scrollTop: $('#quem-somos').offset().top - 60
        }, 1000);
    });
}

$('.owl-carousel').each(function() {
    //Find each set of dots in this carousel
  $(this).find('.owl-dot').each(function(index) {
    //Add one to index so it starts from 1
    $(this).attr('aria-label', index + 1);
  });
});

// window.dispatchEvent(new Event('resize'));
$(window).on('resize', function(){
    // console.log('change' + window.innerWidth);
    // $owl.trigger('refresh.owl.carousel');
    // setTimeout(function(){
        var $carousel = $('.owl-carousel');        
        $carousel.data('owl.carousel')._invalidated.width = true;
        $carousel.trigger('refresh.owl.carousel');
    // }, 2000);
});