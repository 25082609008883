$('#loadMaisProdutos').on('click', function(){
    openLoader();
    var pagina = parseInt($('#paginacao').val());
    var categoria = $('#categoriaPagina').val();

    var tags = [];
    var totalTags = $('.tag.active').length;
    
    for (var i = 0; i < totalTags; i++) {
        // console.log($('.tag.active').eq(i));
        tags.push($('.tag.active').eq(i).data('tag'));
    }
    tags.toString();
    // console.log(tags.toString());
    var datapost = {p: pagina};
    if(categoria !== '' && tags !== '') {
        var datapost = {p: pagina, categoria: categoria, tags: tags.toString()};
    }
    if(categoria !== '' && tags === '') {
        var datapost = {p: pagina, categoria: categoria};
    }
    if(categoria === '' && tags !== '') {
        var datapost = {p: pagina, tags: tags.toString()};
    }
    $.get(
        url + "/ajax/produtos.php", datapost,
        function(data) {
            console.log(data);
            $('#paginacao').val(pagina + 1);
            for (var objKey in data.produtos) {
                // console.log(data.produtos[objKey]);
                $('.listagemprodutos').append(
                    templateProdutos(data.produtos[objKey].link, data.produtos[objKey].titulo, data.produtos[objKey].imagem_destaque[0], pagina)
                );
            }
            setTimeout(() => {
                $('.produtoNoAnime').addClass('reanime');
            }, 500);
            
            console.log('Total de produtos em tela ' + $('.produto').length);
            if($('.produto').length >= data.total) {
                $('#loadMaisProdutos').fadeOut();
            }
            closeLoader();
        }
    );
});

function getTags() {

    openLoader();

    var tags = [];
    var totalTags = $('.tag.active').length;

    for (var i = 0; i < totalTags; i++) {
        // console.log($('.tag.active').eq(i));
        tags.push($('.tag.active').eq(i).data('tag'));
    }

    var pagina = parseInt(1);

    tags.toString();

    // console.log(tags.toString());

    if($('#categoriaPagina')) {
        var categoria = $('#categoriaPagina').val();
        datapost = {p: pagina, categoria: categoria, tags: tags.toString()};
    } else {
        datapost = {p: pagina, tags: tags.toString()};
    }

    $('.listagemprodutos').empty();
    $('.semResultados').fadeOut();

    $.get(
        url + "/ajax/produtos.php", datapost,
        function(data) {
            // console.log(data);
            // $('#paginacao').val(pagina + 1);
            if(data.total !== 0) {
                for (var objKey in data.produtos) {
                    // console.log(data.produtos[objKey]);
                    $('.listagemprodutos').append(
                        templateProdutos(data.produtos[objKey].link, data.produtos[objKey].titulo, data.produtos[objKey].imagem_destaque[0], pagina)
                    );
                }
                setTimeout(() => {
                    $('.produtoNoAnime').addClass('reanime');
                }, 500);

                // console.log('Total de Produtos: ' + data.total);
                // console.log('Total de Produtos em tela: ' + $('.produto').length);

                if(data.total <= 6) {
                    $('#loadMaisProdutos').fadeOut();
                } else if($('.produto').length >= data.total) {
                    $('#loadMaisProdutos').fadeOut();
                } else{
                    $('#loadMaisProdutos').fadeIn();
                }

                closeLoader();
            }
            else {
                $('.semResultados').fadeIn();
                $('#loadMaisProdutos').fadeOut();
                closeLoader();
            }
        }
    );

    $('#paginacao').val('2');
}

function templateProdutos(permalink, titulo, imagem, pagina) {
    var templateProdutos = `
        <div class="col-md-4 col-6">
            <a href="${permalink}" class="produto produtoNoAnime">
                <figure>
                    <img src="${imagem}" alt="${titulo}" class="img-fluid mx-auto d-block">
                </figure>
                <h3>${titulo}</h3>
            </a>
        </div>
    `;
    return templateProdutos;
}

$('.tagProduto').on('click', function(){
    $(this).toggleClass('active');
    $('.firstTag').removeClass('active');
    getTags();
});

//Calculo do menu Moble de Categorias
if ($(window).width() < 769) {
    var countLi = $('.produtos aside nav ul li').length;
    $('.produtos aside nav ul').width(210 * countLi);
    
    var countLiTags = $('.produtos .titleTaxonomy nav ul li').length;
    $('.produtos .titleTaxonomy nav ul').width(160 * countLiTags);
}

//Carousel de Imagem de Single Receitas
var imagensProduto = $('.carouselProduto');
if(imagensProduto.length > 0) {
    imagensProduto.owlCarousel({
        loop: false,
        nav: false,
        dots: true,
        items: 1
    });
}