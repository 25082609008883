const likeTag = $('.favorite');
const likeUp = $('.iconeUp');
const likeDown = $('.iconeDown');
if(likeTag.length > 0) {

	function saveLike($idReceita) {
		$idReceita = parseInt($idReceita, 10);
        // console.log("Favoritos Iniciais: " + localStorage.getItem('natEspecialidadesGostou'));
        likeDown.removeClass('active');

		let favoritos = JSON.parse(localStorage.getItem("natEspecialidadesGostou") || '[]') || [];
		let naoFavoritos = JSON.parse(localStorage.getItem("natEspecialidadesNãoGostou") || '[]') || [];
		// console.log("Favoritos: " + favoritos);
		
		const totalFavs = favoritos.length;
		const totalNaoFavs = naoFavoritos.length;
		// console.log("Total de Favoritos: " + totalFavs);

		if (totalFavs > 0) {
			const receitaFavoritoIdx = favoritos.indexOf($idReceita);
			// console.log('Index de Favoritos: ' + receitaFavoritoIdx);
			if (receitaFavoritoIdx > -1) {
                favoritos.splice(receitaFavoritoIdx, 1);
                likeUp.removeClass('active');
			} else {
				favoritos.push($idReceita);
				likeUp.addClass('active');
				
			}
		} else {
			favoritos.push($idReceita);
			likeUp.addClass('active');
        }
        
        if (totalNaoFavs > 0) {
			const receitaNaoFavoritoIdx = naoFavoritos.indexOf($idReceita);
			// console.log('Index de Favoritos: ' + receitaNaoFavoritoIdx);
			if (receitaNaoFavoritoIdx > -1) {
                naoFavoritos.splice(receitaNaoFavoritoIdx, 1);
                likeDown.removeClass('active');
			}
		}

		localStorage.setItem("natEspecialidadesGostou", JSON.stringify(favoritos));
		localStorage.setItem("natEspecialidadesNãoGostou", JSON.stringify(naoFavoritos));
		// console.log("Favoritos Finais: " + localStorage.getItem('natEspecialidadesGostou'));
		// console.log("Nao Favoritos Finais: " + localStorage.getItem('natEspecialidadesNãoGostou'));
	}
    
    function saveUnLike($idReceita) {
		$idReceita = parseInt($idReceita, 10);
		// console.log("Favoritos Iniciais: " + localStorage.getItem('natEspecialidadesNãoGostou'));

		let naoFavoritos = JSON.parse(localStorage.getItem("natEspecialidadesNãoGostou") || '[]') || [];
		let favoritos = JSON.parse(localStorage.getItem("natEspecialidadesGostou") || '[]') || [];
		// console.log("Favoritos: " + favoritos);
		
		const totalNaoFavs = naoFavoritos.length;
		const totalFavs = favoritos.length;
		// console.log("Total de Favoritos: " + totalFavs);

		if (totalNaoFavs > 0) {
			const receitaFavoritoIdx = naoFavoritos.indexOf($idReceita);
			// console.log('Index de Favoritos: ' + receitaFavoritoIdx);
			if (receitaFavoritoIdx > -1) {
                naoFavoritos.splice(receitaFavoritoIdx, 1);
                likeDown.removeClass('active');
			} else {
				naoFavoritos.push($idReceita);
				likeDown.addClass('active');
				
			}
		} else {
			naoFavoritos.push($idReceita);
			likeDown.addClass('active');
        }
        
        if (totalFavs > 0) {
			const receitaFavoritoIdx = favoritos.indexOf($idReceita);
			// console.log('Index de Favoritos: ' + receitaFavoritoIdx);
			if (receitaFavoritoIdx > -1) {
                favoritos.splice(receitaFavoritoIdx, 1);
                likeUp.removeClass('active');
			}
		}

		localStorage.setItem("natEspecialidadesNãoGostou", JSON.stringify(naoFavoritos));
		localStorage.setItem("natEspecialidadesGostou", JSON.stringify(favoritos));
		// console.log("Favoritos Finais: " + localStorage.getItem('natEspecialidadesGostou'));
		// console.log("Nao Favoritos Finais: " + localStorage.getItem('natEspecialidadesNãoGostou'));
	}

	function checkLike($idReceita) {
		$idReceita = parseInt($idReceita, 10);
		// console.log("Favoritos Iniciais: " + localStorage.getItem('natEspecialidadesGostou'));

		let favoritos = JSON.parse(localStorage.getItem("natEspecialidadesGostou") || '[]') || [];
		// console.log("Favoritos: " + favoritos);
		
		const totalFavs = favoritos.length;
		// console.log("Total de Favoritos: " + totalFavs);

		if (totalFavs > 0) {
			const receitaFavoritoIdx = favoritos.indexOf($idReceita);
			// console.log(receitaFavoritoIdx);
			if (receitaFavoritoIdx > -1) {
				likeUp.addClass('active');
			}
		}

    }
    
    function checkUnFavorite($idReceita) {
		$idReceita = parseInt($idReceita, 10);
		// console.log("Favoritos Iniciais: " + localStorage.getItem('natEspecialidadesNãoGostou'));

		let favoritos = JSON.parse(localStorage.getItem("natEspecialidadesNãoGostou") || '[]') || [];
		// console.log("Favoritos: " + favoritos);
		
		const totalFavs = favoritos.length;
		// console.log("Total de Favoritos: " + totalFavs);

		if (totalFavs > 0) {
			const receitaFavoritoIdx = favoritos.indexOf($idReceita);
			// console.log(receitaFavoritoIdx);
			if (receitaFavoritoIdx > -1) {
				likeDown.addClass('active');
			}
		}

    }

    const idReceitaSingle = likeTag.data('idreceita');
    
	checkLike(idReceitaSingle);
	checkUnFavorite(idReceitaSingle);
    
    likeUp.on('click', function(){
        // console.log('ooooiiii');
        let idReceita = likeTag.data('idreceita');
        // console.log('Código da Receita: ' + idReceita);
		saveLike(idReceita);
	});
    
    likeDown.on('click', function(){
        // console.log('ooooiiii');
        let idReceita = likeTag.data('idreceita');
        // console.log('Código da Receita: ' + idReceita);
		saveUnLike(idReceita);
	});

}