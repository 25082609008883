//Valida E-mail
function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (
        email.search(exclude) != -1 ||
        email.search(check) == -1 ||
        email.search(checkend) == -1
    ) {
        return false;
    } else {
        return true;
    }
}

$('select').selectric();

//Envia E-mail Contato
$("#formContato").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarContato").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nome").val();
    var empresa = $("#empresa").val();
    var departamento = $("#departamento").val();
    var email = $("#email").val();
    var telefone = $("#telefone").val();
    var assunto = $("#assunto").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        $(".erroNome").addClass("showMsg");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
        $(".erroNome").removeClass("showMsg");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        $(".erroEmail").addClass("showMsg");
        erros++;
    } else {
        $("#email").removeClass("errorField");
        $(".erroEmail").removeClass("showMsg");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        $(".erroTelefone").addClass("showMsg");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
        $(".erroTelefone").removeClass("showMsg");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        $(".erroMensagem").addClass("showMsg");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
        $(".erroMensagem").removeClass("showMsg");
    }
    if (empresa == "") {
        $("#empresa").addClass("errorField");
        $(".erroEmpresa").addClass("showMsg");
        erros++;
    } else {
        $("#empresa").removeClass("errorField");
        $(".erroEmpresa").removeClass("showMsg");
    }
    if (assunto == "") {
        $("#assunto").addClass("errorField");
        $(".erroAssunto").addClass("showMsg");
        erros++;
    } else {
        $("#assunto").removeClass("errorField");
        $(".erroAssunto").removeClass("showMsg");
    }
    if (departamento === null) {
        $("#departamento").addClass("errorField");
        $(".erroDepartamento").addClass("showMsg");
        erros++;
    } else {
        $("#departamento").removeClass("errorField");
        $(".erroDepartamento").removeClass("showMsg");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_contato.php",
            $("#formContato").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroContato").fadeOut("slow");
                    $("#formContato")[0].reset();
                    $(".sucessoContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Enviar");
                }
            }
        );
    } else {
        $(".erroContato").fadeIn("slow");
        $("#btnEnviarContato").html("Enviar Mensagem <span></span>");
    }
});

//Envia E-mail Cotação
$("#formCotacao").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarCotacao").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nomeCotacao").val();
    var empresa = $("#empresaCotacao").val();
    var departamento = $("#departamentoCotacao").val();
    var email = $("#emailCotacao").val();
    var telefone = $("#telefoneCotacao").val();
    var assunto = $("#assuntoCotacao").val();
    var mensagem = $("#mensagemCotacao").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        $(".erroNome").addClass("showMsg");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
        $(".erroNome").removeClass("showMsg");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        $(".erroEmail").addClass("showMsg");
        erros++;
    } else {
        $("#email").removeClass("errorField");
        $(".erroEmail").removeClass("showMsg");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        $(".erroTelefone").addClass("showMsg");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
        $(".erroTelefone").removeClass("showMsg");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        $(".erroMensagem").addClass("showMsg");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
        $(".erroMensagem").removeClass("showMsg");
    }
    if (empresa == "") {
        $("#empresa").addClass("errorField");
        $(".erroEmpresa").addClass("showMsg");
        erros++;
    } else {
        $("#empresa").removeClass("errorField");
        $(".erroEmpresa").removeClass("showMsg");
    }
    if (assunto == "") {
        $("#assunto").addClass("errorField");
        $(".erroAssunto").addClass("showMsg");
        erros++;
    } else {
        $("#assunto").removeClass("errorField");
        $(".erroAssunto").removeClass("showMsg");
    }
    if (departamento === null) {
        $("#departamento").addClass("errorField");
        $(".erroDepartamento").addClass("showMsg");
        erros++;
    } else {
        $("#departamento").removeClass("errorField");
        $(".erroDepartamento").removeClass("showMsg");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_cotacao.php",
            $("#formCotacao").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroCotacao").fadeOut("slow");
                    $("#formCotacao")[0].reset();
                    $(".sucessoCotacao").fadeIn("slow");
                    $("#btnEnviarCotacao").html("Enviar");
                }
            }
        );
    } else {
        $(".erroCotacao").fadeIn("slow");
        $("#btnEnviarCotacao").html("Enviar Mensagem <span></span>");
    }
});