
const especialidades = $('.especialidades');
if(especialidades.length > 0) {

	clickParaFavoritar();
	clickParaRemoverFavoritar();
	marcarFavoritas();
}

function clickParaFavoritar() {
	$('.favorito .icone.noFav').on('click', function(){	
		var idReceita = $(this).data('idreceita');
		$(this).removeClass('active');
		$(this).parent().find('.icone.fav').addClass('active');
		salvarFavorito(idReceita);
	});
}

function clickParaRemoverFavoritar() {
	$('.favorito .icone.fav').on('click', function(){
		$(this).removeClass('active');
		$(this).parent().find('.icone.noFav').addClass('active');
		var idReceita = $(this).data('idreceita');
		salvarFavorito(idReceita);
	});
}

function marcarFavoritas() {
	let favoritos = JSON.parse(localStorage.getItem("natEspecialidadesFavoritos") || '[]') || [];
	// console.log("Favoritos: " + favoritos);
	
	const totalFavs = favoritos.length;
	// console.log("Total de Favoritos: " + totalFavs);

	if (totalFavs > 0) {
		for (var objKey in favoritos) {
			// console.log('Favorito a ser marcado: ' + favoritos[objKey]);
			$('#fav'+favoritos[objKey]).find('.icone.noFav').removeClass('active');
			$('#fav'+favoritos[objKey]).find('.icone.fav').addClass('active');
		}
	}
}

function salvarFavorito($idReceita) {
	$idReceita = parseInt($idReceita, 10);

	let favoritos = JSON.parse(localStorage.getItem("natEspecialidadesFavoritos") || '[]') || [];
	// console.log("Favoritos: " + favoritos);
	
	const totalFavs = favoritos.length;
	// console.log("Total de Favoritos: " + totalFavs);

	if (totalFavs > 0) {
		const receitaFavoritoIdx = favoritos.indexOf($idReceita);
		// console.log('Index de Favoritos: ' + receitaFavoritoIdx);
		if (receitaFavoritoIdx > -1) {
			favoritos.splice(receitaFavoritoIdx, 1);
			likeUp.removeClass('active');
		} else {
			favoritos.push($idReceita);
			likeUp.addClass('active');
			
		}
	} else {
		favoritos.push($idReceita);
		likeUp.addClass('active');
	}
	
	localStorage.setItem("natEspecialidadesFavoritos", JSON.stringify(favoritos));
	// console.log("Favoritos Finais: " + localStorage.getItem('natEspecialidadesFavoritos'));
	// console.log("Nao Favoritos Finais: " + localStorage.getItem('natEspecialidadesNãoGostou'));
}