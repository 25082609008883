var carouselJeitonat = $(".carouselJeitoNat");
if(carouselJeitonat.length > 0) {
    carouselJeitonat.owlCarousel({
        loop: true,
        nav: false,
        items: 1,
        autoplay: true,
        // autoplayHoverPause: true,
        dots: true,
        dotsData: true,
        autoplayTimeout:18000,
        smartSpeed:1000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: false,
        touchDrag: true,
        responsive:{
            0:{
                items:1,
                autoplay: true,
                autoHeight:true
            },
            800:{
                items:1,
                autoplay: false,
                autoHeight:true,
                animateOut: 'slideOutDown',
                animateIn: 'slideOutDown'
            },
            1000:{
                items:1
            }
        }
    });
}
